import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  partOne: `Hello!! My name is Mantas. I'm a android developer who is
    passionate about various technologies, but mostly native android. `,
  partTwo: `Currently I work mostly with android using Kotlin. Kotlin is awesome. Sometimes try to experiment writing backend with kotlin (spring boot or ktor). Also tried python. I'm always a learner and a self taught programmer.`,
  partThree: `Want to disscuss something about Android? Or having troubles selecting your next week FPL captain? I'm open for discussions.`,
};

const AboutMe = () => {
  const description = `${pageText.partOne} ${stripTags(pageText.partTwo)} ${stripTags(pageText.partThree)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Mantas', 'MantasBoro', 'Android developer','Kotlin', 'Java', 'RxJava', 'Dagger', 'Gatsby']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.partOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.partTwo)} />
        <p>
          {pageText.partThree}
        </p>
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="location.png"
            height={60}
            alt="location image"
            textH4="Currently living in"
            textH3="Vilnius, Lithuania"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="coffee.png"
            alt="coffee image"
            textH4="Love Tea"
            textH3="Tea + Me = Happiness"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="meeting.png"
            alt="meeting image"
            textH4="Socially Awkward"
            textH3="At times"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="football.png"
            alt="football image"
            textH4="Football fan"
            textH3="Mostly EPL"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Self Taught Programmer"
            textH3="Thanks to the Web Resources"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Got degree in"
            textH3="Math"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
